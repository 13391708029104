<template>
    <div class="col-md-12" style="height: 100%;padding: 157px  0 0 0;">
        <ScannerCamera />
        <div class="scan-camera">
            <div @click="simulateScan()" class="scanner-overlay">
                <div class="scanner-overlay-wrapper">
                    <img class="target-overlay" src="@/assets/img/cam-overlay.png">
                    <span v-show="showMessage" :class="{'scan-explain': true, 'error': error}">{{explainScanValue}}</span>
                </div>
            </div>
            <div>
                <ProductBloc v-if="scannedProduct" :key="productKey" :product="scannedProduct" showMore @showMore="clearTimeout(timers.productsTimer)"  @updateQty="$parent.basketChange()"/>
            </div>
            <div class="scanner-footer">
                <FooterComponent :isOffline="isOffline" v-if="showBasket" :scannedProduct="scannedProduct" disableScanBtn/>
                <div v-if="scanLoyaltyCard" @click="toPaymentMethod" class="scan-loyalty-actions">
                    <span class="px-4 py-2 custom-btn outlined font-weight-normal">Annuler</span>
                </div>
            </div>

        </div>
        <Alert v-if="alertContent!=''" :alertContent="alertContent" @dismissed="$event == 0 ? alertContent = '' : ''" :alertVariant="'danger'"></Alert>
        <audio id="beep" ref="beep" controls hidden>
            <source src="@/assets/sounds/beep_100_0_1.wav" type="audio/wav">
            {{$t('errorSound')}}
        </audio>
    </div>
</template>

<script>

    import {isNotNull} from "@/assets/js/Utils"
    import FooterComponent from "@/components/Food/FooterComponent";
    import ScannerCamera from "@/components/Food/ScannerCamera";
    import ProductBloc from "@/components/Food/ProductBloc";
    import { HalfCircleSpinner } from 'epic-spinners'

    import Alert from "@/components/Common/Alert";
    var Search = require("@/assets/js/SearchProduct");
    var Barcode = require("@/assets/js/Barcode");
    var Basket = require("@/assets/js/Food/Basket");
    var App = require("@/assets/js/App");

    export default {
        name: "Scan",
        components: {ProductBloc, Alert, FooterComponent, HalfCircleSpinner, ScannerCamera},
        props: ['isOffline'],
        data() {
            return {
                ean: '',
                scanned_products: [],
                products: [],
                timers: {
                    productsTimer: null,
                    scannedProductsTimer: null,
                    errorTimer: null
                },
                scanType: "QRCODE",
                inPayment: this.$route.query.inPayment,
                needParameters: this.$route.query.needParameters,
                scanLoyaltyCard: this.$route.query.scanLoyaltyCard,
                scanLockers: this.$route.query.lockerScan,
                alertContent: "",
                explainScanValue: this.$t('messageScanExplain'),
                explainCartValue: this.$t('messageCartExplain'),
                loaded: false,
                scannedProduct: null,
                addingLoader: false,
                productKey: this.generateKey(),
                showMessage: true,
                firstName: null,
                showMessage: true,
                paymentStarted: false,
                firstName: null,
                error: false

            }
        },
        mounted() {
            this.firstName = localStorage.getItem("IEC_FIRSTNAME")
            var showQRScanner = false;
            if(isNotNull(this.inPayment)){
                this.explainScanValue = this.$t('messageScanExplainPayment');
                showQRScanner = true;
                let title = this.firstName ? this.$t("pages.home.title") + ", " +this.firstName : this.$t("pages.home.title")
                let description = this.$t("pages.home.description")
                if(this.scanLoyaltyCard){
                    title = this.$t("pages.loyalty.title")
                    description = this.$t("pages.loyalty.description")
                }else if(this.inPayment == "true"){
                    title = this.$t("pages.payment.title")
                    description = this.$t("pages.payment.terminalScan")
                }
                this.page.title = title
                this.page.description = description
                this.updatePageInfo();
            }else if(isNotNull(this.needParameters)){
                this.explainScanValue = this.$t('messageScanExplainParameters');
                showQRScanner = true;
            }else if(this.scanLockers == "true"){
                this.explainScanValue = this.$t("messageScanExplainLocker")
                this.page.title = this.$t("pages.lockers.title")
                this.page.description = this.$t("pages.lockers.lockerScan")
                this.updatePageInfo();
            }
            var needQRCodeScanner = false;
            var needBarCodeScanner = false;
            if(isNotNull(this.scanLoyaltyCard) && this.scanLoyaltyCard == "true"){
                needBarCodeScanner = true;
            }
            if((isNotNull(this.inPayment) && this.inPayment == "true") || (isNotNull(this.needParameters) && this.needParameters == "true")){
                needQRCodeScanner = true;
            }
            Barcode.start(this.onDecode);

            var scanType = localStorage.getItem("DKC_scanType");
            if ((needBarCodeScanner && !needQRCodeScanner) || (!showQRScanner && isNotNull(scanType) && scanType == "BARCODE")) {
                this.scanType = "BARCODE";
            }else{
                this.scanType = "QRCODE";
            }

            if(isNotNull(this.scanCabin)){
                let self = this;
                this.$parent.showLoader = true;
                setTimeout(function(){
                    self.$parent.showLoader = false;
                    //self.$refs.bagCartCamera.style.display = "none";
                    //self.$refs.bagScanCamera.style.display = "block";
                    self.explainScanValue = self.$t('messageCartExplainCabinScan');
                }, 1000)
            }

            if(isNotNull(this.scanLoyaltyCard)){
                this.explainScanValue = this.$t('messageCartExplainLoyaltyScan');
            }
        },
        methods: {
            onDecode(decodedString) {
                this.$refs.beep.play();
                if(isNotNull(this.inPayment)){
                    this.onPaymentScanned(decodedString);
                }else if(isNotNull(this.needParameters)){
                    this.onParametersScanned(decodedString);
                }else if(isNotNull(this.scanLoyaltyCard)){
                    this.onLoyaltyCardScanned(decodedString);
                }else if(isNotNull(this.scanLockers)){
                    this.onLockerScan(decodedString);
                }else{
                    this.onProductScanned(decodedString);
                }
            },
            onLockerScan(decodedString){
                console.log(decodedString)
                let lockerInfo = JSON.parse(decodedString.codeResult.code)
                this.$parent.showLoader = true;
                let mqttTopic = this.$mqtt.topics.LOCKER.format(lockerInfo)
                this.$mqtt.initMqttClient(()=> {
                    this.$mqtt.publishMqtt(mqttTopic.replace("#","open"), "", () => {
                        this.$mqtt.subscribeMqtt(mqttTopic, () => {
                            let encodedTicketId = localStorage.getItem("encodedTicketId");
                            let message = {
                                subState: "FINISH",
                                basketComplexId: encodedTicketId
                            }
                            Basket.changeSubState(encodedTicketId, "FINISH", () => {
                                this.$mqtt.publishMqtt(this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.SEND_SUBSTATE, JSON.stringify(message), () => {
                                    this.$mqtt.listenMqttMessage(mqttTopic.replace("#","status"), message => {
                                        let decodedMessage = new TextDecoder().decode(message)
                                        if(decodedMessage.toLowerCase() == "closed"){
                                            this.$parent.showLoader = false;
                                            window.location.replace(window.location.origin + '/')
                                        }
                                    })
                                });
                            })
                        })
                    });
                })
            },
            onPaymentScanned(decodedString){
                let code = decodedString.codeResult.code;
                let complexId = localStorage.getItem('IEC_COMPLEX_ID');
                let yomaniKey = "payment_weshop_yomani:";
                let valinaKey = "payment_weshop:";
                let septagoneKey = "payment_septagone:";
                let laneKey = "payment_lane";
                let waitForPayment = true
                try{
                    if(!this.paymentStarted && (code.includes(valinaKey) || code.includes(yomaniKey) || code.includes(septagoneKey) || code.includes(laneKey))) {
                        let terminalId = "";

                        let paymentTopicTerminal = this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.VALINA_PAYMENT;
                        this.paymentStarted = true
                        let customerBasket = Basket.getLocalBasket();
                        if(code.includes(yomaniKey)){
                            paymentTopicTerminal = this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.YOMANI_PAYMENT;
                            terminalId = code.substring(yomaniKey.length);
                        }else if(code.includes(valinaKey)){
                            paymentTopicTerminal = this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.VALINA_PAYMENT;
                            terminalId = code.substring(valinaKey.length);
                        }else if(code.includes(septagoneKey)){
                            terminalId = code.substring(septagoneKey.length);
                            paymentTopicTerminal = this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.SEPTAGONE_PAYMENT.format({ terminalId });
                        }else if(code.includes(laneKey)){
                            waitForPayment = false
                            terminalId = code.split(":")[1];
                            if(customerBasket && terminalId){
                                Basket.initLanePayment(terminalId, customerBasket.id, res => {
                                    if(res){
                                        let transactionId = res.transactionId
                                        if(transactionId){
                                            this.checkLanePayment(transactionId)
                                        }
                                    }
                                })
                            }
                        }
                        this.$parent.showLoader = true;
                        if (!code.includes(laneKey) && complexId && terminalId != "" && customerBasket.basketProducts.length > 0) {
                            let sharedPayment = sessionStorage.getItem("isSharedPayment") == "true";
                            if(sharedPayment){
                                customerBasket = JSON.parse(sessionStorage.getItem("sharedBasket"));
                            }
                            localStorage.setItem("needPublishTerminalPayment", "true");
                            var json = {
                                terminalId : terminalId,
                                userId : complexId,
                                basket : customerBasket,
                                basketPrice : customerBasket.totalAmount,
                                merchantRef : "",
                                orderId : `1000${customerBasket.id}`
                            }

                            let email = localStorage.getItem("email");
                            let tel = JSON.parse(localStorage.getItem("phoneNumber"));


                            if(tel != null && tel.formattedNumber && tel != ""){
                                tel = tel.formattedNumber.replace("+", "00");
                                if(tel != ""){
                                    let tel = JSON.parse(localStorage.getItem("phoneNumber"));
                                    tel = tel.formattedNumber.replace("+", "00");
                                    json.userPhoneNumber = tel;
                                }
                            }
                            if(email != null && email != ""){
                                json.userEmail = email;
                            }

                            var paymentTopicTicket = this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.PAYMENT_TICKET.format({ complexId });

                            if(waitForPayment){

                                this.$mqtt.subscribeMqtt(paymentTopicTicket, () => {
                                        if (localStorage.getItem("needPublishTerminalPayment") != null) {
                                            this.$mqtt.publishMqtt(paymentTopicTerminal, JSON.stringify(json), function () {
                                                localStorage.removeItem("needPublishTerminalPayment");
                                            });
                                        }
                                        this.$mqtt.listenMqttMessage(paymentTopicTicket, message => {
                                            var obj = JSON.parse(message.toString());
                                            this.$parent.showLoader = false;
                                            if (obj.terminalPayment != null) {
                                                if(obj.clientTicket != null) {
                                                    localStorage.setItem('ticketYomani', obj.clientTicket);
                                                }
                                                this.$router.push({ name:"PaymentDone", query: { hash: obj.hashpan, pointsToRemove: obj.pointsToRemove, tId: obj.terminalId, loyaltyCard: obj.loyaltyCard}})
                                            }else if(obj.error != null) {
                                                this.$router.push({name:"PaymentMethods",params: Object.assign({}, this.$route.params, {error: 'true', demo: this.$parent.demo}) });
                                            }
                                        })
                                })
                            }else{
                                this.$mqtt.subscribeMqtt(paymentTopicTicket, () => {
                                        if (localStorage.getItem("needPublishTerminalPayment") != null) {
                                            this.$mqtt.publishMqtt(paymentTopicTerminal, JSON.stringify(json), () => {
                                                localStorage.removeItem("needPublishTerminalPayment");
                                                setTimeout(() => {
                                                    this.$router.push({ name:"PaymentDone", query: { sellerContextSent: true }})
                                                } , 2000)
                                            });
                                        }
                                })
                            }
                        }
                    }
                }catch(e){
                    console.error("Scanner Error", e)
                }
            },
            onParametersScanned(decodedString){
                var controlKey = "/p=";
                if(decodedString.includes(controlKey)){
                    var pathname = decodedString.substring(window.location.origin.length);
                    App.checkParameters(pathname, this);
                    localStorage.setItem("DKC_needPublishPoster", "true");
                    window.location.replace(window.location.origin + '/');
                }else{
                    this.alertContent = this.$t('scanErrorParameters');
                }
            },
            onLoyaltyCardScanned(decodedString){
                localStorage.setItem("DKC_loyaltyCard", decodedString.codeResult.code);
                this.$router.push('/paymentMethods');
            },
            onProductScanned(decodedString){
                this.addingLoader = true
                let self = this;
                let tmpList = [];
                if (decodedString.codeResult) {
                    this.ean = decodedString.codeResult.code;
                } else {
                    this.ean = decodedString.substring(decodedString.lastIndexOf('=') + 1);
                }
                //this.ean = "3228881011138";

                if (true) {
                    this.scanned_products.push(this.ean);
                    Search.getProductByEan(this.ean.split("_")[0], function(product) {
                        if (!product.error) {
                            clearTimeout(self.timers.productsTimer);
                            tmpList.push(product);
                            product.ean = self.ean;
                            self.products = tmpList;
                            self.scannedProduct = product;
                            self.addingLoader = false;
                            self.productKey = self.generateKey()
                            self.showMessage = false

                            /* if(self.ean != "4255834610406"){
                                Search.getCrossSellProducts(product.id, crossSellProduts => {
                                    if(crossSellProduts.length > 0){
                                        localStorage.setItem("IEC_productCrossSell", JSON.stringify(crossSellProduts));
                                        self.$parent.productCrossSell = crossSellProduts;
                                    }
                                })
                            } */

                            if(self.$parent.nbProductsCart < 20){
                                Basket.addProductToLocalBasket(product);
                                self.timers.productsTimer = setTimeout(()=> {
                                    self.scannedProduct = null;
                                }, 6000)

                                //MQTT.publishMessage("SCAN_PRODUCT", JSON.stringify(scannedProduct));
                                self.$parent.basket = Basket.getBasket();
                                self.$parent.basketChange();

                            }
                        }else{
                            self.explainScanValue = self.$t("productNotFound")
                            self.error = true
                            self.timers.errorTimer = setTimeout(()=> {
                                self.error = false
                                self.explainScanValue = self.$t("messageScanExplain");
                            }, 3000)
                        }
                    });
                }
            },
            async checkLanePayment(transactionId){
                console.log("check")
                let res = await Basket.checkLanePayment(transactionId)
                let transactionStatus = res.data.transactionStatus;
                if(transactionStatus == "DONE"){
                    this.$router.push({ name:"PaymentDone" })
                }else if(transactionStatus == "WAITING"){
                    setTimeout(() => this.checkLanePayment(transactionId), 5000)
                }else if(transactionStatus == "REFUSED"){
                    this.$parent.alertContent = this.$t("paymentMethods.alertPaymentRefused")
                    this.$parent.alertVariant = "danger"
                    this.$router.push({ name:"PaymentMethods", query : {error: true} })
                }
            },
            onSubmit(evt) {
                evt.preventDefault();
            },
            existInBasket(ean) {
                var exist = false;
                this.$parent.basket.lockedProducts.forEach(function(lockedProduct) {
                    if (lockedProduct == ean) {
                        exist = true;
                    }
                })
                return exist;
            },
            generateKey(){
                return 'scannedProduct-'+ parseInt(Math.random() *1000)
            },
            toPaymentMethod(){
                Basket.updateBasket(newBasket => {
                    localStorage.setItem("IEC_basket", JSON.stringify(newBasket));
                    this.$emit('basketChgt');
                    this.$router.push('/paymentMethods');
                })
            },
            simulateScan(ean){
                let codeToScan = ean
                if(this.scanLockers){
                    codeToScan = {
                        codeResult: {
                            code: '{"lockerLocation": "testaar", "lockerID": 1}'
                        }
                    }
                }
                if(process.env.NODE_ENV != 'production'){
                    this.onDecode(codeToScan)
                }
            },
            clearTimeout(timer){
                clearTimeout(timer)
            }
        },
        computed:{
            showBasket(){
                return !this.needParameters && !this.inPayment && !this.scanLoyaltyCard && !this.scanLockers
            },
            page() {
                return {
                    title: this.$t("pages.home.title"),
                    description: this.$t("pages.home.description"),
                    showBackBtn: false,
                    showFilterBtn: false
                }
            }
        },
        beforeRouteLeave(to, from, next) {
            /* eslint-disable */
            Barcode.reset()
            next();
        },
        watch:{
            firstName(){

                let title = this.$t("pages.home.title")
                if(this.scanLoyaltyCard){
                    title = this.$t("pages.loyalty.title")
                }
                let description = this.$t("pages.home.description")
                if(this.scanLoyaltyCard){
                    description = this.$t("pages.loyalty.description")
                }
                if(this.scanLockers){
                    title = this.$t("pages.lockers.title")
                    description = this.$t("pages.lockers.lockerScan")
                }
                this.page.title = title
                this.page.description = description
                this.updatePageInfo();
            }
        },
        beforeDestroy(){
            this.$mqtt.endClient()
        },
    }
</script>
